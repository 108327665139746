import React, {Component} from "react";
import GuideItem from "./GuideItem";

export class ItemContent {
    public subtitle: string
    public time: string
    public title: string
    public picture: string
    public pictureBkp: string
    public id: string;
    public active: boolean;

    constructor(title: string, subtitle: string, time: string, picture: string, pictureBkp: string, id: string, active: boolean) {
        this.title = title;
        this.subtitle = subtitle;
        this.time = time;
        this.picture = picture;
        this.pictureBkp = pictureBkp;
        this.id = id;
        this.active = active;
    }

}

function objToQueryString(obj: any) {
    const keyValuePairs = [];
    for (const key in obj) {
        keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
    }
    return keyValuePairs.join('&');
}

interface GuideState {
    items: ItemContent[],
    isFetching: boolean,
    loggedIn: boolean,
    showButton: boolean,
    endDateTime: string,
}

interface GuideProps {
}

export default class GuideList extends Component<GuideProps, GuideState> {
    items = Array<ItemContent>();
    private timer: any;
    private SERVICE_URL = "https://boot.pluto.tv/v4/start?appName=web&appVersion=5.109.0-6577a51c82bdba0d9a0a2bab4950dd43d469ea83&deviceVersion=98.0.0&deviceModel=web&deviceMake=firefox&deviceType=web&clientID=fccf2d7c-4af0-4790-b9fe-7593047c633a&clientModelNumber=1.0.0&serverSideAds=true&constraints=&clientTime=2022-03-24T14%3A40%3A32.303Z"
    private CHANNELS_URL = "https://service-channels.clusters.pluto.tv/v2/channels"
    private TIMELINE_URL: string = "https://service-channels.clusters.pluto.tv/v2/guide/timelines";

    public getGuide(id: string, append: boolean) {
        let ch = Array<ItemContent>();
        let queryObj;
        if (append) {
            queryObj = ({
                start: this.state.endDateTime,
                channelIds: id,
                duration: "240",
            });
            ch = this.state.items;
        } else {
            queryObj = ({
                start: new Date(Date.now()).toISOString(),
                channelIds: id,
                duration: "240",
            });
        }
        console.log("START: " + queryObj.start);
        console.log("getGuide");
        document.body.style.cursor = "wait"
        console.log("NEW REQUEST");
        console.log(`TOKEN: ${localStorage.getItem("token")}`)
        console.log(`URL: ${this.TIMELINE_URL}?${objToQueryString(queryObj)}`)
        //retrieve data
        console.log("CICLO");
        console.log(queryObj.start)
        fetch(`${this.TIMELINE_URL}?${objToQueryString(queryObj)}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token"),
            },
        })
            .then(response => response.json())
            .then(result => {
                console.log(result);
                const chId = result.data[0].channelId;
                console.log("CHANNELID" + chId);
                for (let k = 0; k < result.data[0].timelines.length; k++) {
                    let i = result.data[0].timelines[k]
                    if (i.episode.description === "Nessuna informazione disponibile") {
                        this.setState({
                            showButton: false,
                            isFetching: false,
                        })
                        break;
                    }
                    let d = new Date(i.start);
                    let b = new ItemContent(i.episode.name, i.episode.description, `${d.toDateString()} ${d.toTimeString().split("GMT")[0]}`, i.episode.poster.path, i.episode.series.tile.path, chId, false);
                    ch.push(b)
                }
                this.setState({
                    endDateTime: new Date(new Date(result.meta.endDateTime).getTime() + 300000).toISOString(),
                    isFetching: false,
                });

                this.setState({items: ch})


            })
            .catch(e => {
                console.log(e);
                this.setState({...this.state, isFetching: false, loggedIn: false});
            });
        console.log("LENGTH: " + ch.length)
        document.body.style.cursor = "default"
        this.setState({showButton: true})
    }

    forwardRef() {
        this.getChannels();
    }

    constructor(a: any) {
        super(a);
        this.state = {
            isFetching: false,
            items: [],
            loggedIn: false,
            showButton: false,
            endDateTime: new Date(Date.now()).toISOString(),
        };
        this.login();
    }

    componentDidMount() {
        this.getChannels();
    }

    componentWillUnmount() {
        clearInterval(this.timer);
        this.timer = null;
    }

    updateItems = (newItems: Array<ItemContent>) => {
        this.setState({
            items: newItems
        });
        window.scrollTo(0, 0);
    }

    loadMore = (e: any) => {
        this.setState({isFetching: true});
        console.log("MORE");
        this.getGuide(this.state.items[this.state.items.length - 1].id, true)
    }


    render() {
        // console.log(this.state.data)
        // console.log(`TYPE: ${typeof this.state.data}`)
        // this.items = Array<ItemContent>();
        // if (this.state.data !== undefined) {
        //     for (let k = 0; k < this.state.data.length; k++) {
        //         let i = this.state.data[k]
        //         let b = new ItemContent(i.name, i.summary, "", i.colorLogoPNG.path, i.thumbnail.path)
        //         this.items.push(b)
        //     }
        // }
        // console.log(`LUNGHEZZA: ${this.items.length}`)
        return (
            <div className="GuideList">
                <button className="loadMore" disabled={this.state.isFetching} onClick={this.getChannels}>
                    <h3>{"< Back"}</h3></button>
                {this.state.items.map((i: ItemContent) => (
                    <GuideItem
                        pictureUrl={i.picture}
                        title={i.title} subtitle={i.subtitle} time={i.time} picture2={i.pictureBkp}
                        getGuide={(id, update) => this.getGuide(id, update)} id={i.id} clickable={i.active}/>
                ))}
                {this.state.showButton &&
                    <button className="loadMore" disabled={this.state.isFetching} onClick={this.loadMore}><h3>Load
                        more</h3></button>}
            </div>
        );
    }

    login = () => {
        if (!this.state.loggedIn) {
            this.setState({...this.state, isFetching: true});
            document.body.style.cursor = "wait"
            //retrieve data
            fetch(this.SERVICE_URL)
                .then(response => response.json())
                .then(result => {
                    this.setState({isFetching: false, loggedIn: true})
                    console.log(`TOKEN: ${result.sessionToken}`)
                    localStorage.setItem("token", result.sessionToken)
                })
                .catch(e => {
                    console.log(e);
                    this.setState({...this.state, isFetching: false});
                });
            this.setState({...this.state, isFetching: false});
            document.body.style.cursor = "default"
        }
    }

    getChannels = () => {
        this.setState({...this.state, isFetching: true});
        document.body.style.cursor = "wait"
        //retrieve data

        fetch(this.CHANNELS_URL, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("token"),
            },
        })
            .then(response => response.json())
            .then(result => {
                let ch = Array<ItemContent>();
                for (let k = 0; k < result.length; k++) {
                    let i = result[k]
                    let b = new ItemContent(i.name, i.summary, "", i.colorLogoPNG.path, i.thumbnail.path, i._id, true)
                    ch.push(b)
                }
                this.setState({items: ch, isFetching: false})
                // console.log(`TOKEN: ${result.sessionToken}`)
                // localStorage.setItem("token", result.sessionToken)
            })
            .catch(e => {
                console.log(e);
                this.setState({...this.state, isFetching: false, loggedIn: false});
            });
        this.setState({...this.state, isFetching: false});
        document.body.style.cursor = "default"
    }

}