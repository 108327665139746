import React, {Component} from "react";
import {ItemContent} from "./GuideList";

interface ItemProps {
    pictureUrl: string,
    title: string,
    subtitle: string,
    time: string,
    picture2: string,
    getGuide: (id: string, update: boolean) => void,
    id: string,
    clickable: boolean,
}

export default class GuideItem extends Component<ItemProps, any> {

    private timelineUrl: string = "https://service-channels.clusters.pluto.tv/v2/guide/timelines";

    getGuide(update: boolean) {
        document.body.style.cursor = "loading";
        window.scrollTo(0, 0);
        this.props.getGuide(this.props.id, update);
        // let queryObj = ({
        //     duration: "240",
        //     channelIds: this.props.id,
        //     start: new Date(Date.now()).toISOString(),
        // });
        // console.log("getGuide");
        // document.body.style.cursor = "wait"
        // console.log("NEW REQUEST");
        // console.log(`TOKEN: ${localStorage.getItem("token")}`)
        // console.log(`URL: ${this.timelineUrl}`)
        // console.log(`QUERY STRING: ${objToQueryString(queryObj)}`)
        // //retrieve data
        // let ch = Array<ItemContent>();
        // console.log("CICLO");
        // console.log(queryObj.start)
        // fetch(`${this.timelineUrl}?${objToQueryString(queryObj)}`, {
        //     method: 'GET',
        //     headers: {
        //         Accept: 'application/json',
        //         'Content-Type': 'application/json',
        //         'Authorization': "Bearer " + localStorage.getItem("token"),
        //     },
        // })
        //     .then(response => response.json())
        //     .then(result => {
        //         console.log(result);
        //
        //         console.log("INFO");
        //         for (let k = 0; k < result.data[0].timelines.length; k++) {
        //             let i = result.data[0].timelines[k]
        //             if (i.episode.description === "No information available") {
        //                 break;
        //             }
        //             let b = new ItemContent(i.episode.name, i.episode.description, new Date(i.start).toString(), i.episode.poster.path, i.episode.series.tile.path, i._id)
        //             ch.push(b)
        //         }
        //         this.props.update(ch)
        //
        //     })
        //     .catch(e => {
        //         console.log(e);
        //         this.setState({...this.state, isFetching: false, loggedIn: false});
        //     });
        // console.log("LENGTH: " + ch.length)
        document.body.style.cursor = "default"

    }

    render() {
        return (
            <div className="wrapper" onClick={() => this.props.clickable? this.getGuide(false): null}>
                <div className="GuideItem">
                    <img className="GuideImg" src={this.props.pictureUrl} alt={""} srcSet={this.props.picture2}/>
                </div>
                <div className="GuideText">
                    <div className="GuideTitle">
                        <span>{this.props.title}</span>
                    </div>
                    <div className="GuideDescrption">
                        <span>{this.props.subtitle}</span>
                    </div>
                    <div className="GuideTime">
                        <span>{this.props.time}</span>
                    </div>
                </div>
            </div>
        );
    }
}