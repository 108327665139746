import React, {LegacyRef, MutableRefObject} from 'react';
import './App.css';
import GuideList from "./GuideList";


function App() {
    const guideRef = React.createRef<GuideList>();
    return (
        <div className="appRoot">
            <div className="logo" onClick={()=>{
                window.location.reload();
            }}>
                <img className="logoimg"
                     src="https://deadline.com/wp-content/uploads/2020/01/pluto-logo.png" alt={""}/>
            </div>
            <h2>Guide</h2>
            <div className="root">
                <GuideList ref={guideRef}/>
                {/*<div className="GuideList">*/}
                {/*    <GuideItem time="11:20-11:45" title="TITLETITILETITLETITLE"*/}
                {/*               subtitle="La vita di Timmy viene stravolta dall'arrivo dei fantagenitori, creature magiche in grado di esaudire ogni suo desiderio!"*/}
                {/*               pictureUrl="https://images.pluto.tv/channels/60801317a0ccef00072aaf75/featuredImage.jpg?fill=blur&fit=fill&fm=jpg&h=900&q=75&w=1600"/>*/}
                {/*    <GuideItem time="11:20-11:45" title="TITLEfdlkfjpasj fodpafòoekpwoafpe" subtitle="SUBTITLE"*/}
                {/*               pictureUrl="https://images.pluto.tv/channels/60801317a0ccef00072aaf75/featuredImage.jpg?fill=blur&fit=fill&fm=jpg&h=900&q=75&w=1600"/>*/}
                {/*</div>*/}
            </div>
        </div>
    );
}

export default App;
